import React from 'react';
import styled from 'styled-components';

import { ReactComponent as CaminhaoLixo } from '../assets/homeIcons/caminhao-de-lixo.svg';
import { ReactComponent as Co2 } from '../assets/homeIcons/co2 1.svg';
import { ReactComponent as Plantar } from '../assets/homeIcons/plantar 1.svg';

import PlasticoNoOceanoIcon from '../assets/icons/PlasticoNoOceano.png';
import Co2Icon from '../assets/icons/Co2avoid.png';
import ReciclagemIcon from '../assets/icons/Reciclagem.png';
import ArvoresSalvasIcon from '../assets/icons/ArvoresSalvas.png';


const Divider = styled.div`
  height: 2px; /* Espessura da linha */
  width: 100%; /* Largura total */
  background: linear-gradient(to right, rgba(32, 50, 80, 1), rgba(255, 255, 255, 0)); /* Gradiente que vai do sólido ao transparente */
`;


const AddicionalInfoContainer = styled.div`
    margin: 0px 20px 10px 20px;
    flex-direction: column;
    display: flex;
    width:100%;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`;

const CircleBackground = styled.div`
  width: 100px; /* Tamanho do fundo circular */
  height: 100px; /* Tamanho do fundo circular */
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza o ícone no círculo */
`;

const InfoTextContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 75%;
`;

const InfoLabel = styled.span`
  text-align: center;
  justify-content: center;
  display: flex;
  font-size: 20px; 
  font-weight:700;
  color: #203250; 
`;

const InfoValue = styled.span`
  text-align: center;
  justify-content: center;
  display: flex;
  font-size: 20px; 
  font-weight:500;
  color: #203250; 
`;

const DashboardContainer = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background: ${(props) => props.color};
  border-bottom: 4px solid #ffffff;
  position: relative;
`;

const MainText = styled.h1`
  font-size: 45px;
  color: #203250;

  margin-bottom: 20px;
`;

const SubText = styled.span`
  color: #203250;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 5px;
  text-align: center;
`;

const Triangle = styled.div<{ color: string }>`
  width: 50px;
  height: 50px;
  background: ${(props) => props.color};
  border: 4px solid #ffffff;
  border-top: none;
  border-left: none;
  rotate: 45deg;
  position: absolute;
  bottom: -27.8px;
  z-index: 1;
`;

interface DashboardCardProps {
  weight?: string;
  subText?: string;
  color: string;
  emissions?: string; // Adiciona as novas informações
  plasticRemoved?: string;
  treesSaved?: string;
}



export default function DashboardCard({ weight, subText, color, emissions, plasticRemoved, treesSaved }: DashboardCardProps) {
  const AdditionalInfoData = [
    {
      label: 'Emissões Evitadas',
      value: emissions ? `${emissions} kg`: 'N/A',
      icon: <img src={Co2Icon} alt="Emissões Evitadas"/>, // Ícone PNG
    },
    {
      label: 'Plástico Evitado nos Oceanos',
      value: plasticRemoved ? `${plasticRemoved} kg` : 'N/A',
      icon: <img src={PlasticoNoOceanoIcon} alt="Plástico Evitado"/>, // Ícone PNG
    },
    {
      label: 'Árvores Salvas',
      value: treesSaved ? `${treesSaved} árvores` : 'N/A',
      icon: <img src={ArvoresSalvasIcon} alt="Árvores Salvas"/>, // Ícone PNG
    },
  ];

  return (
    <DashboardContainer color={color}>
      {/* Renderiza as informações adicionais */}
      <AddicionalInfoContainer>
        {AdditionalInfoData.map((info, index) => (
          info.value !== undefined && (
            <React.Fragment key={index}>
              <IconContainer>
                <CircleBackground>
                  {info.icon} {/* Ícone correspondente */}
                </CircleBackground>
                <InfoTextContainer>
                  <InfoLabel>
                    {info.label}
                  </InfoLabel> {/* Texto correspondente */}
                  <InfoValue>
                    {info.value} <br/>
                    {info.label === 'Emissões Evitadas' && ("CO2-e") }
                  </InfoValue>
                </InfoTextContainer>
              </IconContainer>
              {/* O Divider agora sempre aparecerá após cada IconContainer */}
              <Divider />
            </React.Fragment>
          )
        ))}
      </AddicionalInfoContainer>


      <SubText>{subText}</SubText>
      <MainText>{weight} Kg</MainText>
      
      <Triangle color={color} />
    </DashboardContainer>
  );
}

