import React from 'react';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import DashboardCard from '../../components/DashboardCard';
import DashboardCardSecond from '../../components/DashboardCardSecond';
import googleplay from '../../assets/googleplay.png';
import appstore from '../../assets/appstore.png';

import { ReactComponent as Instagram } from '../../assets/Insta.svg';

import { IPositiveImpact } from '../../models/PositiveImpact';
import { ICollectTreatment } from '../../models/CollectTreatment';
import CollectTreatmentService from '../../services/CollectTreatmentService';
import { title } from 'process';
import { useParams } from 'react-router-dom';
import CollectResiduesService from '../../services/CollectResiduesService';

import UserService from '../../services/UserService';

import { isAuthenticated } from '../../services/auth';
import { baseImg } from '../../App';

const MainContainer = styled.div`
  max-width: 768px;
  height: 100vh;
  margin: auto;
`;

const HeaderContainer = styled.div`
  background-color: #203250;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
`;

const HeaderTitle = styled.h1`
  text-transform: none;
  margin: 20px;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  font-size: 1.8rem;

  //   ${css`
    //     @media (max-width: 500px) {
    //       font-size: 1.8rem;
    //     }

    //     @media (max-width: 400px) {
    //       font-size: 1.5rem;
    //     }
    //
  `}
  //
`;

const HeaderImg = styled.img`
  width: 150px;
  height: 150px;

  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media (min-width: 1921px) {
    width: 120px;
    height: 120px;
  }
`;

const HighlightedText = styled.span`
  font-weight: bold;
  color: #4caf50; // escolha a cor que deseja
  text-transform: capitalize;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 60vh;
  background: #70b6da;
`;

const FristTextContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const FristText = styled.h1`
  color: white;
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: none;

  ${css`
    @media (max-width: 500px) {
      font-size: 1.8rem;
    }

    @media (max-width: 400px) {
      font-size: 1.5rem;
    }
  `}
`;

const SecondTextContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const SecondText = styled.h1`
  color: white;
  font-size: 2.5rem;
  text-transform: none;

  ${css`
    @media (max-width: 500px) {
      font-size: 1.8rem;
    }

    @media (max-width: 400px) {
      font-size: 1.5rem;
    }
  `}
`;

const AppsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;
`;
const InstagramContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;
`;

const StoreIcons = styled.img`
  @media (max-width: 500px) {
    width: 150px;
    height: 45px;
  }
`;

const InstagramLogo = styled(Instagram)`
  width: 70px;
  height: 70px;

  @media (max-width: 500px) {
    width: 50px;
    height: 50px;
  }
`;

const calcInfo = [
  {
    title: 'Papel & Papelão',
    weight: '',
    img: '',
    percent: 0
  },
  {
    title: 'Metal',
    weight: '',
    img: '',
    percent: 0
  },
  {
    title: 'Vidro',
    weight: '',
    img: '',
    percent: 0
  },
  {
    title: 'Plástico',
    weight: '',
    img: '',
    percent: 0
  },
    {
    title: 'Orgânico',
    weight: '',
    img: '',
    percent: 0
  }
];

const results = {
  co2Avoid: "",
  plasticOceanSaved: "",
  treesSaved: ""
};

const mensagem = 'Os impactos da Siri';

interface CardsInfo {
  title: string;
  value: string;
  img?: string | undefined;
  percent?: string;
}

interface PieDashBoardInfo {
  title: string;
  value: number;
  percent: number;
  img?: string | undefined;
}

export default function Dashboard() {
  const { id } = useParams<{ id: string }>();
  const user_id = Number(id);

  const [collectTreatments, setCollectTreatments] = useState<ICollectTreatment[]>([]);
  const [positiveImpact, setPositiveImpact] = useState<IPositiveImpact[]>([]);
  const [totalWeight, setTotalWeight] = useState<number | string>(0);
  const [calcCardsInfo, setCalcCardsInfo] = useState(calcInfo);
  const [positiveResult, setPositiveResult] = useState(results);
  const [mensagemTela, setMensagemTela] = useState(mensagem);
  const [userName, setUserName] = useState<string | null>(null);
  const [userImg, setUserImg] = useState<string | undefined>(undefined);

  const formatNumber = (num: number) => {
    return Math.round(num).toLocaleString('pt-BR');
  };

  async function checkLoginStatus() {
    if (isAuthenticated()) {
      const user = await UserService.getById(user_id);
      setUserName(user.name);
      setUserImg(user.img ? baseImg + user.img : '');
      setMensagemTela(`${user.name}! Veja seus impactos positivos. O planeta agradece!`);
      return true;
    } else {
      const user = await UserService.getBasicInfo(user_id);
      setUserName(user.name);
      setUserImg(user.img ? baseImg + user.img : '');
      setMensagemTela(`${user.name}: Resultados da Gestão de Resíduos Sólidos`);
      return false;
    }
  }

  async function getPositiveImpact() {
    // Obtém os dados das coletas e resíduos
    let resultCollectTreatmentsInfo = await CollectTreatmentService.getById(user_id);
    let resultCollectResidues = await CollectResiduesService.getById(user_id);

    // Verifica se não há dados de coletas
    if (resultCollectTreatmentsInfo.length === 0 && resultCollectResidues.length === 0) {
      // Busca dados padrão se não houver dados do usuário
      resultCollectTreatmentsInfo = await CollectTreatmentService.getById(1);
      resultCollectResidues = await CollectResiduesService.getById(1);

      // Define a mensagem padrão para todos os usuários
      setMensagemTela(`Impactos da Siri`);
    }

    // Processamento e uso da variável resultCollectTreatmentsInfo
    const positiveResult2 = positiveResult;
    
    const co2Avoided = resultCollectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.avoid_co2, 0);

    positiveResult2.co2Avoid = formatNumber(resultCollectTreatmentsInfo.reduce((acc, item) => acc + item.positive_impact.avoid_co2, 0));
    positiveResult2.treesSaved = formatNumber(co2Avoided / 168);
    

    setPositiveResult(positiveResult2);
    setCollectTreatments(resultCollectTreatmentsInfo);

    // const validTitles = ['Vidro', 'Papel', 'Plásticos', 'Metais', 'Orgânicos'];

    const validTitles = ['Vidro', 'Papel', 'Plásticos', 'Metais', 'Orgânicos'];

    const validCollectTreatments = resultCollectResidues.reduce<PieDashBoardInfo[]>((acc, obj) => {
      const titleTrimmed = obj.title ? obj.title.trim().split(' ')[0] : 'Outros';
      if (validTitles.includes(titleTrimmed)) {
        const exist = acc.find((item) => item.title === titleTrimmed);
        if (exist) {
          exist.value += obj.value;
          exist.percent += obj.percent;
          exist.img = exist.img ? exist.img : obj.img;
        } else {
          acc.push({ title: titleTrimmed, value: obj.value, percent: obj.percent, img: obj?.img ? obj.img : undefined });
        }
      } else {
        const otherItem = acc.find((item) => item.title === 'Outros');
        if (otherItem) {
          otherItem.value += obj.value;
        } else {
          acc.push({ title: 'Outros', value: obj.value, percent: obj.percent, img: obj.img });
        }
      }
      return acc;
    }, []);



    const totalWeight = formatNumber(validCollectTreatments.reduce((acc, item) => acc + item.value, 0));
    
    const totalPlastic = validCollectTreatments.find((item) => item.title === "Plásticos") || {value: 0};

    positiveResult2.plasticOceanSaved = formatNumber(totalPlastic.value * 0.3);

    const plastic = validCollectTreatments.find((treatment) => treatment.title === 'Plásticos');
    const paper = validCollectTreatments.find((treatment) => treatment.title === 'Papel');
    const glass = validCollectTreatments.find((treatment) => treatment.title === 'Vidro');
    const metal = validCollectTreatments.find((treatment) => treatment.title === 'Metais');
    const organic = validCollectTreatments.find((treatment) => treatment.title === 'Organicos');

    const calc = calcInfo;

    calc[0].weight = paper?.value ? formatNumber(paper.value) : '0';
    if (calc[0].weight !== '0') {
      calc[0].img = paper?.img ? paper.img : '';
      calc[0].percent = parseFloat(paper?.percent?.toFixed(2) ?? '0');
    }

    calc[1].weight = metal?.value ? formatNumber(metal.value) : '0';
    if (calc[1].weight !== '0') {
      calc[1].img = metal?.img ? metal.img : '';
      calc[1].percent = parseFloat(metal?.percent?.toFixed(2) ?? '0');
    }

    calc[2].weight = glass?.value ? formatNumber(glass.value) : '0';
    if (calc[2].weight !== '0') {
      calc[2].img = glass?.img ? glass.img : '';
      calc[2].percent = parseFloat(glass?.percent?.toFixed(2) ?? '0');
    }

    calc[3].weight = plastic?.value ? formatNumber(plastic.value) : '0';
    if (calc[3].weight !== '0') {
      calc[3].img = plastic?.img ? plastic.img : '';
      calc[3].percent = parseFloat(plastic?.percent?.toFixed(2) ?? '0');
    }
    calc[4].weight = organic?.value ? formatNumber(organic.value) : '0';
    if (calc[4].weight !== '0') {
      calc[4].img = organic?.img ? organic.img : '';
      calc[4].percent = parseFloat(plastic?.percent?.toFixed(2) ?? '0');
    }



    setCalcCardsInfo(calc);
    setTotalWeight(totalWeight);
  }

  useEffect(() => {
    checkLoginStatus();
    getPositiveImpact();
  }, []);

  return (
    <MainContainer>
      <HeaderContainer>
        {userImg ? <HeaderImg src={userImg}/> : null }
        <HeaderTitle>{mensagemTela}</HeaderTitle>
      </HeaderContainer>
      <DashboardCard
        weight={totalWeight.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        subText="Total de Resíduos Destinados"
        color="#ffffff"
        emissions={positiveResult.co2Avoid}
        plasticRemoved={positiveResult.plasticOceanSaved}
        treesSaved={positiveResult.treesSaved}
      />
      { calcCardsInfo[0].weight !== '0' ?
      <DashboardCardSecond weight={calcCardsInfo[0].weight} residue="papel & papelao" object={calcCardsInfo[0]} color="#4F82C1" cardNumber={0} /> : null }
      { calcCardsInfo[1].weight !== '0' ?
      <DashboardCardSecond weight={calcCardsInfo[1].weight} residue="metal" object={calcCardsInfo[1]} color="#FBBC00" cardNumber={1} /> : null }
      { calcCardsInfo[2].weight !== '0' ?
      <DashboardCardSecond weight={calcCardsInfo[2].weight} residue="vidro" object={calcCardsInfo[2]} color="#97A228" cardNumber={2} /> : null }
      { calcCardsInfo[3].weight !== '0' ?
      <DashboardCardSecond weight={calcCardsInfo[3].weight} residue="plástico" object={calcCardsInfo[3]} color="#B93435" cardNumber={3} /> : null }
      { calcCardsInfo[4]?.weight !== '0' ?
      <DashboardCardSecond weight={calcCardsInfo[4].weight} residue="Orgânico" object={calcCardsInfo[4]} color="#8B4513" cardNumber={4} /> : null }
      <FooterContainer>
        <FristTextContainer>
          <FristText>A coleta Seletiva é feita</FristText>
          <FristText>
            em parceria com a <b>Siri</b>.
          </FristText>
        </FristTextContainer>
        <SecondTextContainer>
          <SecondText>Conheca as nossas</SecondText>
          <SecondText>redes, servicos e App</SecondText>
        </SecondTextContainer>
        <AppsContainer>
          <a href="https://play.google.com/store/apps/details?id=com.sgrsiri.sirigeradorapp">
            <StoreIcons alt="Get it on Google Play" src={googleplay} />
          </a>
          <a href="https://apps.apple.com/br/app/sir%C3%AD-gest%C3%A3o-de-res%C3%ADduos/id6502182657?l=en-GB">
            <StoreIcons alt="Download on the App Store" src={appstore} />
          </a>
        </AppsContainer>
        <InstagramContainer>
          <InstagramLogo />
        </InstagramContainer>
      </FooterContainer>
    </MainContainer>
  );
}