import React from 'react';
import styled from 'styled-components';
import { Pie } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  ArcElement, 
  Tooltip, 
  Legend, 
  LineController, 
  LineElement, 
  PointElement, 
  CategoryScale, 
  LinearScale 
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  ArcElement, 
  Tooltip, 
  Legend, 
  ChartDataLabels,
  LineController, 
  LineElement, 
  PointElement, 
  CategoryScale, 
  LinearScale
);

const PieDashBoardContainer = styled.div`
  width: auto;
  height: auto;
  margin: auto;
`;

type PieDashBoardProps = {
  labels?: string[];
  values?: number[];
};

export default function ImpactPie({ labels = [], values = [] }: PieDashBoardProps) {
  const colorMap: { [key: string]: string } = {
    'Plásticos': '#f72e03',
    'Vidro': '#34A853',
    'Metais': '#FBBC04',
    'Papel': '#0337f7',
    'Orgânico' : '#8B4513',
    'Total Coletado': '#000000',
    'Outros': '#00FFFF'
  };

  const backgroundColors = labels.map(label => colorMap[label] || '#888888');

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Quantidade do resíduo',
        data: values,
        backgroundColor: backgroundColors,
        hoverOffset: 4
      }
    ]
  };

const options: any = {
  plugins: {
    legend: {
      display: true,
      position: 'top' as const,
      labels: {
        boxWidth: 20,
        padding: 15,
        color: '#333',
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem: any) {
          const dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
          const index = tooltipItem.dataIndex;
          const label = tooltipItem.label || "Resíduo";
          const value = dataset.data[index] || 0;
          const total = dataset.data.reduce((acc: number, curr: number) => acc + curr, 0);
          const percentage = total ? ((value / total) * 100).toFixed(2) : 0;
          return `${label}: ${Math.round(value)} Kg (${percentage}%)`;
        },
      },
    },
    datalabels: {
      color: '#000', // Cor padrão para rótulos
      backgroundColor: '#ccc', // Fundo cinza atrás dos rótulos
      borderRadius: 4, // Bordas arredondadas no fundo
      padding: 5, // Espaçamento interno para o fundo
      font: {
        size: 12, // Valor fixo como número
        weight: 'bold',
      },
      formatter: (value: number, context: any) => {
        const total = context.dataset.data.reduce((acc: number, curr: number) => acc + curr, 0);
        const percentage = total ? ((value / total) * 100).toFixed(1) : 0;
        return `${Math.round(value)} Kg\n(${percentage}%)`;
      },
      anchor: 'center', // Centraliza os rótulos inicialmente
      align: (context: any) => {
        const dataset = context.dataset.data;
        const value = dataset[context.dataIndex];
        const total = dataset.reduce((acc: number, curr: number) => acc + curr, 0);
        return value / total < 0.1 ? 'end' : 'center'; // Alinha para fora se necessário
      },
      offset: (context: any) => {
        const dataset = context.dataset.data;
        const value = dataset[context.dataIndex];
        const total = dataset.reduce((acc: number, curr: number) => acc + curr, 0);
        return value / total < 0.1 ? 15 : 0; // Ajusta o deslocamento
      },
      callout: {
        enabled: true, // Ativa linhas de ligação
        borderColor: '#000',
        borderWidth: 1,
        length: 20, // Comprimento da linha
        margin: 10, // Margem entre o rótulo e a linha
      },
    },
  },
};

  return (
    <PieDashBoardContainer>
      <Pie data={data} options={options} />
    </PieDashBoardContainer>
  );
}