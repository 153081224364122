export interface IPack {
  id: number;
  name: string;
  description: string;
  composition: Array<IComposition>; // [ 'Papelão', 'Plástico']
  company_id: number;
  company_name?: string;
  residue_id?: string;
  composition_name?: string;
}
export interface IComposition {
  residue_id: number;
  code: string;
  description: string;
  unit?: string;
  img: string;
}

export const blankPack: IPack = {
  id: 0,
  name: '',
  description: '',
  composition: [],
  company_id: 0
};

export const packProps = Object.keys(blankPack);
